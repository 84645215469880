.choose-plan-modal > div:first-child {
    background: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(20px);
}

.choose-plan-modal-title {
    font-size: 24px;
}

.choose-plan-modal-wrapper {
    text-align: center;
    margin-top: 48px;
}

.choose-plan-modal-wrapper .close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
}

.plan-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 48px;
}

.pay-modal-wrapper,
.complete-modal-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 48px 36px;
    background-color: #fff;
    border-radius: 30px;
    color: rgba(51, 51, 51, 1);
}

.pay-modal-wrapper .close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

.plan-card {
    position: relative;
    width: 430px;
    border-radius: 6px;
    border: 4px solid var(--mainDarker);
    text-align: center;
    padding-top: 12px;
    margin: 0 auto;
}

.plan-card-check-mark {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: var(--mainDarker);
    color: #fff;
    text-align: center;
    padding-top: 3px;
}

.plan-card-title {
    font-weight: 500;
    font-size: 16px;
    color: rgba(38, 70, 121, 1);
}

.plan-card-price {
    font-weight: 700;
}

.plan-card-price-number {
    display: inline-block;
    font-size: 48px;
    color: rgba(38, 70, 121, 1);
    margin: 0 4px;
}

.plan-card-footer {
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    padding: 12px;
    background: linear-gradient(90deg, #3386d2 0%, #662d91 100%);
}

.pay-modal-hint,
.complete-modal-hint {
    color: rgba(196, 196, 196, 1);
    font-size: 14px;
    font-weight: 700;
    text-align: center;
}

.pay-modal-title,
.complete-modal-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 20px;
}

.pay-modal-field-label {
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 8px;
}

.pay-modal-field-required-mark {
    color: var(--mainDarker);
    display: inline-block;
    margin-left: 4px;
}

.pay-modal-field-cvc input::-webkit-outer-spin-button,
.pay-modal-field-cvc input::-webkit-inner-spin-button {
    /* Chrome, Safari, Edge, Opera */
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.pay-modal-field-cvc input[type='number'] {
    -moz-appearance: textfield;
}

.pay-modal-submit-button {
    width: 100%;
    border-radius: 99px;
    padding: 16px 0;
    background: var(--mainDarker);
    color: #fff;
    margin-top: 32px;
    font-size: 17px;
    font-weight: 700;
}

.pay-modal-stripe {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 14px 24px;
}

.pay-modal-stripe--invalid {
    border: 1px solid red;
}

.pay-modal-submit-button--disable {
    background: #f4f4f4;
    color: rgba(173, 173, 173, 1);
    cursor: not-allowed;
}

.complete-modal-wrapper {
    padding: 32px 84px;
    text-align: center;
}

.complete-modal-complete-image {
    width: 60px;
    height: 60px;
}

.complete-modal-button {
    width: 258px;
    padding: 16px 0;
    font-weight: 500;
    background: linear-gradient(90.2deg, #ed0973 0.14%, #662d91 99.83%);
    border-radius: 4px;
    color: #fff;
}
