:root {
    --navBarHeight: 44px;
}

.wrapper {
    display: flex;
    max-width: 1100px;
    margin: 0 auto;
    padding-top: calc(var(--navBarHeight) + 38px);
}

@media screen and (min-width: 1441px) {
    .wrapper {
        max-width: 1460px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1 1;
}
