.toastWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 18px;
    box-shadow: 0 0 6px 0 #a2b1c4;
    border-radius: 2px;
    background-color: white;
}

.large {
    flex-direction: column;
    width: 350px;
    height: 188px;
}

.large > div {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.large > div > p {
    font-family: 'PingFang', sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #637280;
    margin: 0;
    margin-left: 10px;
}

.toastWrapper > p {
    font-family: 'PingFang', sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #637280;
    margin: 0;
    margin-left: 20px;
}
