* {
    box-sizing: border-box;
    font-family:
        'Pretendard Variable',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Barlow',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif,
        'Microsoft JhengHei',
        '微軟正黑體';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Pretendard Variable';
    font-weight: 45 920;
    font-style: normal;
    font-display: swap;
    src: url('assets/font/PretendardVariable.woff2') format('woff2-variations');
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*:focus {
    outline: none;
}

@font-face {
    font-family: TaiwanPearl-Regular;
    src:
        url(https://cdn.jsdelivr.net/gh/max32002/TaiwanPearl@2.125/webfont/TaiwanPearl-Regular.woff2) format('woff2'),
        url(https://cdn.jsdelivr.net/gh/max32002/TaiwanPearl@2.125/webfont/TaiwanPearl-Regular.woff) format('woff');
}

button {
    outline: none;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
}

.primary-linear-background {
    background: transparent linear-gradient(90deg, #601598 0%, #f2539d 100%) 0% 0% no-repeat padding-box;
}
