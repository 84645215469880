.banner-wrapper {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 1000;
}

.banner-image {
    width: 1147px;
    height: 90px;
}

.start-button {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 85%;
    background-color: rgba(51, 51, 51, 1);
    border-radius: 8px;
    font-weight: 500;
    line-height: 24px;
    padding: 10px 20px;
    color: #fff;
    width: max-content;
    transition: all 0.2s linear;
}

.start-button:hover {
    background-color: rgba(38, 38, 38, 1);
}

.close-button {
    position: absolute;
    top: 4px;
    right: 4px;
    color: #fff;
}
