.plan-card {
    display: inline-block;
    width: 300px;
    min-height: 600px;
    box-shadow: -4px -4px 20px 0px rgba(0, 0, 0, 0.25);
    margin-right: 20px;
    border-radius: 6px;
    background: #fff;
    font-size: 14px;
    background: transparent;
}

.plan-card:nth-child(2) {
    width: 330px;
    min-height: 676px;
}

.plan-card:nth-child(2) .plan-card-star-icon {
    display: inline-block;
}

.plan-card:last-child {
    margin-right: 0;
}

.plan-card-header {
    position: relative;
    width: 100%;
    padding: 16px 21px;
    color: #fff;
    border-radius: 6px 6px 0 0;
    text-align: start;
}

.plan-card-title {
    font-weight: 500;
    font-size: 18px;
}

.plan-card-star-icon {
    display: none;
    position: absolute;
    top: 21px;
    right: 21px;
}

.plan-card-content {
    width: 100%;
    padding: 10px 20px;
}

.plan-card-price-unit {
    font-size: 14px;
    font-weight: 500;
}

.plan-card-upgrade-button {
    width: 100%;
    padding: 15px;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 12px;
}

.plan-card-upgrade-button--disable {
    background: rgba(244, 244, 244, 1);
    color: rgba(173, 173, 173, 1);
    cursor: not-allowed;
}

.plan-card-feature {
    display: flex;
    margin: 16px 0;
    align-items: center;
}

.plan-card-feature-icon {
    flex-shrink: 0;
}

.plan-card-feature-content {
    margin: 0;
    margin-left: 10px;
    text-align: left;
}

.plan-card-feature-content-em {
    color: var(--mainDarker);
}

.plan-card-description {
    font-weight: 500;
}
