.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.DateRangePickerInput__withBorder {
    border: solid 1px rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    overflow: hidden;
    background-color: #f7f9fa;
}

.DateRangePickerInput > .DateInput_1 {
    overflow: hidden;
    width: 100px;
    background-color: #f7f9fa;
}

.DateRangePickerInput > .DateInput_1 > input {
    font-size: 16px;
    width: 90px;
    text-align: center;
    padding: 3px;
    padding-top: 5px;
    background-color: #f7f9fa;
}

.DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2 {
    left: -374px !important;
}

.DateInput_input__focused {
    border-bottom: 2px solid #398ded;
}

.CalendarDay__selected_span {
    background: #398ded;
    color: white;
    border: 1px solid #398ded;
}

.CalendarDay__selected_span:hover,
.CalendarDay__selected:hover {
    background: #7fb4f1;
    border: 1px solid #7fb4f1;
    color: white;
}

.CalendarDay__selected {
    background: #398ded;
    color: white;
    border: 1px solid #398ded;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #7fb4f1;
    border: 1px solid #7fb4f1;
    color: white;
}
