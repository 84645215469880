.cookie-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #ffffff;
    padding: 20px 32px;
    z-index: 1200;

    .cookie-banner-content {
        font-family: Noto Sans TC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: justify;
        text-shadow: none;
        color: #333;
    }

    .cookie-banner-btn {
        font-family: Noto Sans TC;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-shadow: none;
        text-wrap: nowrap;
        border-radius: 8px;
        padding: 8px 16px;
        margin-left: 64px;
        color: #fff;
        background-color: #ec0974;

        @media (max-width: 1024px) {
            width: 100%;
            margin-top: 20px;
            margin-left: 0;
        }
    }

    @media (max-width: 1024px) {
        padding: 20px;
        flex-direction: column;
    }
}

.cookie-banner-content a {
    font-family: Noto Sans TC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-decoration-line: underline;
    text-shadow: none;
    color: #376fd5;
}
